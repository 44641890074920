<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <b-row>
            <b-col lg="12" sm="12">
              <b-table-simple bordered>
                <b-tr>
                  <b-th style="width:20%">{{$t('user_role.organization')}}</b-th>
                  <b-td style="width:30%">{{ getColumnName($store.state.commonObj.organizationProfileList, allData.org_id)}}</b-td>
                  <b-th style="width:20%">{{$t('org_pro.service_namel')}}</b-th>
                  <b-td style="width:30%">{{ dropDownCheckService(allData.service_id)}}</b-td>
                </b-tr>
                <b-tr>
                  <b-th style="width:20%">{{$t('warehouse_config.region')}}</b-th>
                  <b-td style="width:30%">{{ getColumnName($store.state.commonObj.officeList, allData.region_office_id)}}</b-td>
                </b-tr>
              </b-table-simple>
            </b-col>
            <b-col lg="12" sm="12" id="form">
              <fieldset class="p-2 w-100">
                <legend class="px-2 w-50 shadow-sm">{{$t('globalTrans.details') }}</legend>
                <div class="p-2">
                  <table class="table" style="width:100%;padding: 5px" border="1">
                    <thead class="thead">
                      <tr>
                        <th style="width:6%">{{ $t('globalTrans.sl_no') }}</th>
                        <th style="width:6%">{{ $t('globalTrans.division') }}</th>
                        <th style="width:20%">{{$t('globalTrans.district')}} </th>
                        <th style="width:20%">{{$t('org_pro_upazilla.upazilla')}} </th>
                      </tr>
                    </thead>
                    <tr v-for="(item, index) in allData.details" :key="index">
                      <b-td>
                        {{ $n(index + 1) }}
                      </b-td>
                      <b-td>
                        {{ getColumnName($store.state.commonObj.divisionList, item.division_id)}}
                      </b-td>
                      <b-td>
                        {{ getColumnName($store.state.commonObj.districtList, item.district_id)}}
                      </b-td>
                      <b-td>
                        {{ getColumnName($store.state.commonObj.upazilaList, item.upazilla_id)}}
                      </b-td>
                    </tr>
                  </table>
                </div>
              </fieldset>
            </b-col>
          </b-row>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  props: ['id'],
  created () {
    const tmp = this.getData()
    this.allData = tmp
  },
  data () {
    return {
      allData: []
    }
  },
  computed: {
  },
  methods: {
    getData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    dropDownCheckService (vals) {
        const dataVal = []
        if (vals) {
          vals.forEach((item, key) => {
            const dataFind = this.$store.state.licenseRegistration.commonObj.serviceNamesList.find(dt => dt.value === item)
            if (this.$i18n.locale === 'bn') {
              dataVal.push(dataFind.text_bn)
            } else {
              dataVal.push(dataFind.text_en)
            }
          })
        }
        return dataVal.toString()
      },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        } else {
          return obj.text_en
        }
      } else {
          return ''
      }
    }
  }
}
</script>
